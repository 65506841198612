import { createGlobalStyle } from 'styled-components';

import '@fontsource/open-sans';
import '@fontsource/michroma';

import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/400-italic.css';

import '@fontsource/open-sans/variable.css';
import '@fontsource/open-sans/variable-italic.css';
import '@fontsource/michroma/400.css';

const GlobalStyles = createGlobalStyle`
 :root {
    --qx-green: #003000;
    --qx-dkgreen: #2E382D;
    --qx-yellow: #F9B300;
    --qx-dkyellow: #d49800;
    --qx-grey: #808285;
    --qx-ltgrey: #dfe0e0;
    --qx-dkgrey: #303338;
    --qx-brown: #301805;
    --black: #000000;
    --white: #ffffff;
    --fontfamily-default: "Open Sans","Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --fontfamily-variable: "Open SansVariable", "Open Sans","Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --fontfamily-qx: "Michroma","Open Sans","Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    //https://type-scale.com/ - Perfect Fourth
    --fontsize-3x: 4.209rem; //67.34px
    --fontsize-2x: 3.157rem; //50.52ps
    --fontsize-xl: 2.369rem; //37.90px
    --fontsize-lg: 1.777rem; //28.43px
    --fontsize-md: 1.333rem; //21.33px
    --fontsize-base: 1rem; //16px
    --fontsize-sm: 0.875rem; //14px
    --fontsize-xs: 0.75rem; //12px
    --fontsize-xxs: 0.563rem; //9px
    --gutter-x: 1.5rem;
    --space-5x: 10rem;
    --space-4x: 6rem;
    --space-3x: 5rem;
    --space-2x: 4rem;
    --space-xl: 3rem;
    --space-lg: 2.5rem;
    --space-md: 1.5rem;
    --space-base: 1rem;
    --space-sm: 0.5rem;
    --space-xs: 0.25rem;
    --qx-border-radius: 2px;
  }

  *, ::after, ::before {
    box-sizing: border-box;
  }

  div {
    display: block;
  }

  html {
    font-size: 16px;

    @media (max-width: 767.98px) {
      font-size: var(--fontsize-sm);
    }
  }

  /* Font Styles */
  body {
    font-size: 1rem;
    font-family: var(--fontfamily-default);
    color: var(--qx-dkgreen);
    line-height: 1.75;
    font-weight: normal;
    position: relative;
    overflow-x: hidden;
  }
  @supports (font-variation-settings: normal) {
    body {
      font-family: var(--fontfamily-variable);
    }
  }
  h1, h2, h3, h4 {
    margin-top: 0;
    font-family: var(--fontfamily-qx);
    line-height: 1.01;
  }

  h1 {
    font-size: var(--fontsize-3x);
    text-transform: uppercase;
  }
  
  h2 {
    font-size: var(--fontsize-xl);
    text-transform: uppercase;

    @media (max-width: 767.98px) {
      font-size: var(--fontsize-lg);
    }
  }
  
  h3 {font-size: var(--fontsize-lg);}
  
  h4 {font-size: var(--fontsize-md);}
  
  h5 {font-size: var(--fontsize-md);}
  
  small, .text_small {font-size: var(--fontsize-sm);}

  a {
    color: var(--qx-yellow);
    transition: color 0.2s ease-in-out;

    &:hover {
      color: var(--qx-dkyellow);
    }
  }

  .blockquote {
    font-size: var(--fontsize-md);
    font-weight: 700;
  }

  /* Containers */
  .container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-right: var(--space-base);
    padding-left: var(--space-base);

    @media (min-width: 576px) {
      padding-right: var(--space-xl);
      padding-left: var(--space-xl);
    }
    @media (min-width: 768px) {
      padding-right: var(--space-2x);
      padding-left: var(--space-2x);
    }
    @media (min-width: 992px) {
      padding-right: var(--space-3x);
      padding-left: var(--space-3x);
    }
    @media (min-width: 1200px) {
      padding-right: calc(var(--space-xl) + max(0px, 50% - 1200px /2));
      padding-left: calc(var(--space-xl) + max(0px, 50% - 1200px /2));
    }
  }

  .container-full-width {
    @media (max-width: 767.98px) {
      padding-right: 0;
      padding-left: 0;
    }
    > * {
      padding-right: var(--space-base);
      padding-left: var(--space-base);

      @media (min-width: 576px) {
        padding-right: var(--space-xl);
        padding-left: var(--space-xl);
      }
      @media (min-width: 768px) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button, .btn {
    background: var(--qx-yellow);
    background-color: var(--qx-yellow);
    outline-color: var(--qx-yellow);
    border-color: var(--qx-yellow);
    color: var(--qx-green);
    border: 0;
    padding: 0.45rem 1rem;
    border-radius: var(--qx-border-radius);
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 700;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    &:hover {
      background: var(--qx-dkyellow);
      background-color: var(--qx-dkyellow);
      outline-color: var(--qx-dkyellow);
      border-color: var(--qx-dkyellow);
      color: var(--black);
    }
  }

  .btn {
    display: inline-block;
    text-decoration: none;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    border: 0;
    height: 8px;
  }

  
  strong {
    color: var(--qx-yellow);
    font-size: 1.03em;
  }

  img {
    max-width: 100%;
  }

  /* Forms */
  section,
  article,
  .section-squished-padding {
    padding-top: var(--space-3x);
    padding-bottom: var(--space-3x);
  }

  .section-squish {
    padding-top: 0;
    padding-bottom: 0;
  }

  /* List reset */
  .list-reset {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
  }

  /* Forms */
  .contact__form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--space-md);

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .form-control {
    font-size: var(--fontsize-base);
    width: 100%;

    padding: var(--space-sm);
    line-height: 1.5;
    background-color: (--white);
    background-clip: padding-box;
    border: 2px solid var(--qx-green);
    appearance: none;
    border-radius: var(--qx-border-radius);
    outline: 0;
  }
  
  .formcontact__form--full-width {
    @media (min-width: 768px) {
      grid-column: 1 / 3;
    }
  }

  /* Screen Reader only, Mobile/Desktop onlys */
  .sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    -webkit-clip-path: inset(50%) !important;
      clip-path: inset(50%) !important;  /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }

  .only-on-mobile {
    @media (min-width: 992px) {
      display: none;
    }
  }

  .only-on-desktop {
    @media (max-width: 991.98px) {
      display: none;
    }
  }

  /* Picture and Text section */
  .pic-text-section {

    > h2 {
      flex: 0 0 auto;
      width: 100%;
    }

    > .container {
      display: flex;
      flex-wrap: wrap;
      @media (min-width: 768px) {
        flex-wrap: nowrap;
      }
    }

    .img-wrapper,
    .content-wrapper {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      @media (min-width: 768px) {
        flex: 0 0 auto;
        width: 50%;
      }
    }
  }

  .content-wrapper {
    @media (min-width: 768px) {
      padding-left: var(--space-xl);
    }
  }
`;

export default GlobalStyles;
