import React from 'react';
import { Link } from 'gatsby';
import FooterStyles from '../styles/FooterStyles';
import FooterSkyline from '../assets/svg/footer-skyline.inline.svg';
import FooterLogo from '../assets/svg/footer-logo.inline.svg';
import FooterIcons from '../assets/svg/footer-icons.inline.svg';
import WBENC from '../assets/images/Wlogo-white.png';

export default function Footer() {
  return (
    <FooterStyles>
      <div className="skyline-wrapper">
        <FooterSkyline />
      </div>

      <div className="footer-wrapper">
        <FooterIcons />
        <div className="footer-container container">
          <div className="footer-col footer-col-main">
            <FooterLogo className="footer-logo" />
            <p className="footer-short-desc">
              Quality Excavation is a local women-owned business, conveniently
              located along Belmont Avenue's industrial corridor in the heart of
              Chicago.
            </p>
            <img
              src={WBENC}
              className="wbenc-logo"
              alt="Certified WBENC Women's Business"
            />
          </div>
          <div className="footer-col footer-col-secondary">
            <ul className="footer__details list-reset">
              <li className="footer__details--item">
                <svg
                  className="footer__details--svg"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon-mappin" fill="white" />
                </svg>
                <a
                  href="https://goo.gl/maps/DLbdkh5FmGWDmBwP9"
                  target="_blank"
                  rel="noreferrer"
                >
                  2432 W. Barry Ave., Chicago, IL 60618
                </a>
              </li>
              <li className="footer__details--item">
                <svg
                  className="footer__details--svg"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon-phone" fill="white" />
                </svg>
                <ul className="footer__details--phones list-reset">
                  <li className="footer__details--phone-item">
                    Phone: <a href="tel:7732480963">773-248-0963</a>
                  </li>
                  <li className="footer__details--phone-item">
                    Fax: <a href="tel:7732480963">773-248-6150</a>
                  </li>
                </ul>
              </li>
              <li className="footer__details--item">
                <svg
                  className="footer__details--svg"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon-email" fill="white" />
                </svg>
                <a href="mailto:information@qualityexcavation.com">
                  information@qualityexcavation.com
                </a>
              </li>
              <li className="footer__details--item">
                <svg
                  className="footer__details--svg"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <use href="#icon-calendar" fill="white" />
                </svg>
                <ul className="footer__details--hours list-reset">
                  <li className="footer__details--hours-item">
                    Monday - Friday : 8AM to 4PM
                  </li>
                  <li className="footer__details--hours-item">
                    Saturday : by appointment only
                  </li>
                  <li className="footer__details--hours-item">
                    Sunday : closed
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="footer-col footer-col-tertiary">
            <ul className="footer__sitemap list-reset">
              <li className="footer__sitemap--item">
                <Link to="/">Home</Link>
              </li>
              <li className="footer__sitemap--item">
                <Link to="/whyquality">Why Quality</Link>
              </li>
              <li className="footer__sitemap--item">
                <Link to="/services">Services</Link>
              </li>
              <li className="footer__sitemap--item">
                <Link to="/safety">Safety</Link>
              </li>
              <li className="footer__sitemap--item">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>

            <ul className="list-reset">
              <li className="footer__sitemap--item">
                <Link to="/services#excavation">Excavation</Link>
              </li>
              <li className="footer__sitemap--item">
                <Link to="/services#earth-retention-shoring">
                  Earth Retention and Shoring
                </Link>
              </li>
              <li className="footer__sitemap--item">
                <Link to="/services#demoliton">Demolition</Link>
              </li>
              <li className="footer__sitemap--item">
                <Link to="/services#site-clearance-prep">
                  Site Clearance & Preparation
                </Link>
              </li>
              <li className="footer__sitemap--item">
                <Link to="/service#onsite-screening">On-Site Screening</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </FooterStyles>
  );
}
