import styled from 'styled-components';

export const FooterStyles = styled.footer`
  font-size: var(--fontsize-sm);
  position: relative;
  width: 100%;

  .footer-wrapper {
    background-color: var(--black);
    color: var(--white);
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  //
  //
  // Columns
  //
  .footer-col {
    margin-bottom: 2rem;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;

    @media (min-width: 768px) {
      margin-bottom: 5rem;
    }
  }

  .footer-col-main {
    margin-top: 1rem;
    @media (min-width: 768px) {
      flex: 0 0 auto;
      width: 20%;
    }
  }

  .footer-col-secondary {
    @media (min-width: 768px) {
      margin-top: 3rem;
      flex: 0 0 auto;
      width: auto;
    }
  }

  .footer-col-tertiary {
    @media (min-width: 768px) {
      margin-top: 3rem;
      flex: 0 0 auto;
      width: auto;
    }

    @media (min-width: 992px) {
      display: flex;
      wrap: nowrap;

      ul {
        padding-left: var(--space-lg);
      }
    }
  }

  //
  //
  // Footer logo
  //
  .footer-logo {
    width: 100%;
    height: auto;
    max-width: 220px;
  }

  .wbenc-logo {
    width: 100%;
    height: auto;
    max-width: 150px;
    margin-top: 1rem;
  }

  //
  //
  // Address, phone, email, hours
  //
  .footer__details {
    ul li {
      margin-bottom: var(--space-sm);
      margin-top: var(--space-xs);
    }
  }
  .footer__details--item {
    display: flex;
    align-items: top;
    margin-bottom: var(--space-md);
  }

  .footer__details--svg {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  //
  //
  // Sitemap links
  //
  .footer__sitemap--item {
    margin-bottom: var(--qx-space-sm);

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  //
  //
  // Skyline svg
  //
  .skyline-wrapper {
    width: 100%;
    height: 0;
    padding-top: 16.666%;
    position: relative;
    top: 0;
    left: 0;

    svg {
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export default FooterStyles;
